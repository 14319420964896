// Import Image for Banner Component
import BannerImage from '../../../assets/Images/CR_lokation_10.png';
import TopBannerImage from '../../../assets/Images/background_page2.png';

// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

// Page Data
const Data = {
  pageName: 'Banner Test Page',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    level_2_Id: 'Livsændringer',
    pageTitle: 'FrontpageBannerTest',
    pageDescription: 'Tester components på frontpage',
    bannerImage: TopBannerImage,
    bannerContent: '<h1>Banner text accepts HTML</h1>',
    bottomContent: [
      {
        componentName: 'Banner',
        componentData: {
          image: BannerImage,
          header: 'Banner Header Text',
          manchet: 'Banner Manchet Text',
          url: '/test',
          urlText: 'Læs mere',
        },
      },
    ],
    desktopTools: {},
  },
  pageContent: [
    {
      componentName: 'Banner',
      componentData: {
        image: BannerImage,
        header: 'Banner Header Text',
        manchet: 'Banner Manchet Text',
        url: '/test',
        urlText: 'Læs mere',
        fullWidth: true,
      },
    },
  ],
};

export default Data;
